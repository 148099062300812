import romanApi from "@/lib/net/romanApi";
import useSWR from "swr";

export interface FantasyPoint {
  fantasyPoint: number;
  startDate: Date;
}

interface FantasyPointsResponse {
  recentFantasyPoints: FantasyPoint[];
}

interface UseRecentFantasyPointsReturn {
  data?: FantasyPointsResponse;
  error: any;
  isLoading: boolean;
}

const fetcher = async (url: string) => {
  const response = await romanApi.get<FantasyPointsResponse>(url);

  const transformedData: FantasyPointsResponse = {
    recentFantasyPoints: response.recentFantasyPoints.map((fp) => ({
      ...fp,
      startDate: new Date(fp.startDate),
    })),
  };

  return transformedData;
};

export const useRecentFantasyPoints = (playerId: string, roundId: string): UseRecentFantasyPointsReturn => {
  const { data, error, isLoading } = useSWR<FantasyPointsResponse>(
    `/soccer/player/average-fp/${playerId}/${roundId}`,
    fetcher,
  );

  return {
    data,
    error,
    isLoading,
  };
};
