import { useNavigate } from "react-router-dom";

export const BackButton = ({ onClick, className }: { onClick?: () => void; className?: string }) => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    if (onClick) return onClick();
    navigate(-1);
  };

  return (
    <button
      onClick={handleGoBack}
      onKeyDown={(e) => {
        if (e.key === "Enter") handleGoBack();
      }}
      type="button"
      className={className}
    >
      <img className="hover:cursor-pointer w-6 h-6" src="/assets/back.svg" alt="back button" draggable={false} />
    </button>
  );
};
