import axios from "axios";
import useSWR from "swr";

interface CoinGeckoResponse {
  "the-open-network": {
    usd: number;
  };
}

const fetchTonDollarRate = async (): Promise<number> => {
  const url = "https://api.coingecko.com/api/v3/simple/price?ids=the-open-network&vs_currencies=usd";
  const response = await axios.get<CoinGeckoResponse>(url);
  return response.data["the-open-network"].usd;
};

// Use the SWR data in a function or React component
export const useTonUsdPrice = () => {
  const { data, error, isLoading } = useSWR("tonDollarRate", fetchTonDollarRate);

  return { data, isLoading, error };
};
