import { CloseIcon } from "@/components/Icons";
import Tabs from "@/components/Tabs";
import { FantasyPointChart } from "./FantasyPointChart";
import { Prize } from "./Prize";

interface RoundInfoProps {
  sportName: string;
  roundId: string;
  onClose: () => void;
}

export const RoundInfo = ({ sportName, roundId, onClose }: RoundInfoProps) => {
  return (
    <div
      className="fixed inset-0 z-50 bg-black backdrop-blur-sm  px-2 py-4 overflow-y-scroll"
      onClick={(e: React.MouseEvent) => e.stopPropagation()}
    >
      <div className="flex justify-center pb-10 w-full">
        <p className="text-white">{sportName}</p>
        <CloseIcon className="absolute right-4 top-4" onClick={onClose} />
      </div>
      <Tabs>
        <Tabs.Item title="Prizes">
          <Prize roundId={roundId} />
        </Tabs.Item>
        <Tabs.Item title="Player Fantasy Points">
          <FantasyPointChart />
        </Tabs.Item>
      </Tabs>
    </div>
  );
};
