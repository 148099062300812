interface ModalProps {
  children: React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
  className?: string;
  contentClassName?: string;
}

export const CustomModal = ({ children, isOpen, onClose, className, contentClassName }: ModalProps) => {
  return (
    <>
      {isOpen && (
        <div className={`fixed inset-0 z-50 flex items-center justify-center bg-[#000000B3] ${className}`}>
          <div className={`bg-background rounded-[10px] shadow-lg px-4 pt-5 pb-[30px] w-[375px] ${contentClassName}`}>
            <div className="flex justify-end hover:cursor-pointer" onClick={onClose}>
              <img className="w-6 h-6" src="/assets/close.svg" alt="close icon" />
            </div>
            {children}
          </div>
        </div>
      )}
    </>
  );
};
