import { Center } from "@/components/Center";
import { LoadingSpinner } from "@/components/LoadingSpinner";
import { useErrorModalContext } from "@/contexts/ErrorModalContext";
import { useSoccerRounds } from "@/hooks/soccer/useSoccerRounds";
import type { RomanApiError } from "@/lib/net/romanApi";
import { getErrorMessage } from "@/utils/error";
import { useCallback } from "react";
import { SoccerRoundsCard } from "./SoccerRoundsCard";

export const SoccerRounds = () => {
  const { openModal } = useErrorModalContext();
  const handleError = useCallback(
    (error: RomanApiError) => {
      const message = getErrorMessage(error);
      openModal("Error getting rounds", message);
    },
    [openModal],
  );
  const { data, isLoading } = useSoccerRounds(handleError);

  if (isLoading)
    return (
      <Center>
        <LoadingSpinner />
      </Center>
    );

  return (
    <div className="px-2">
      {data?.rounds
        ?.sort((a, b) => a.entryOpenTime - b.entryOpenTime)
        .map((round) => (
          <SoccerRoundsCard key={round.id} round={round} />
        ))}
    </div>
  );
};
