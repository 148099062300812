import romanApi, { type RomanApiError } from "@/lib/net/romanApi";
import type { Round } from "@/types/Round";
import { useEffect } from "react";
import useSWR from "swr";

interface SoccerRoundsResponse {
  rounds: Round[];
}

const fetcher = (url: string) => romanApi.get<SoccerRoundsResponse>(url);

export const useSoccerRounds = (handleError: (err: RomanApiError) => void) => {
  const { data, error } = useSWR<SoccerRoundsResponse>("/soccer/rounds", fetcher);

  useEffect(() => {
    if (error) {
      handleError(error);
    }
  }, [error, handleError]);

  return {
    // temp fix for background image
    data: {
      rounds: data?.rounds.map((round) => ({ ...round, backgroundImageUrl: "/temp/league01.png" })),
    },
    isLoading: !error && !data,
  };
};
