import { type User, useInitData, useLaunchParams } from "@telegram-apps/sdk-react";

const formatUserName = (user: User) => {
  const { firstName, lastName } = user;
  if (!firstName) return lastName;
  if (!lastName) return firstName;
  return `${firstName} ${lastName}`;
};

type UseTelegram = {
  name: string | undefined;
  telegramUserId: number | undefined;
  // Used to authenticate the user
  initDataRaw: string | undefined;
};
export const useTelegram = (): UseTelegram => {
  const initData = useInitData();
  const { initDataRaw } = useLaunchParams();
  const user = initData?.user;
  const telegramUserId = user?.id;
  return {
    name: user ? formatUserName(user) : undefined,
    telegramUserId,
    initDataRaw,
  };
};
