// Need to import this to use ton/@ton library
import "./polyfill";

import { createRoot } from "react-dom/client";
import "./index.css";
import { Root } from "./Root";

const container = document.querySelector("#root");

if (container) {
  const root = createRoot(container);
  root.render(<Root />);
} else {
  throw new Error("ルートコンテナが見つかりません");
}
