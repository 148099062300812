export const OptionButton = ({
  option,
  isPercent,
  onClick,
}: { option: number; isPercent?: boolean; onClick: () => void }) => {
  return (
    <button
      onClick={onClick}
      className="flex items-center justify-center px-5 py-2 rounded-lg bg-gray-500"
      type="button"
      key={option}
    >
      <p className="text-white">
        {option}
        {isPercent && "%"}
      </p>
    </button>
  );
};
