import { useTonUsdPrice } from "@/hooks/useTonUsdPrice";
import { LoadingSpinner } from "./LoadingSpinner";

export const TonUsdPill = ({ className }: { className?: string }) => {
  const { data, isLoading } = useTonUsdPrice();

  if (isLoading) {
    return (
      <div
        className={`flex justify-center items-center bg-button-background px-2.5 py-1 rounded-3xl text-icon text-xs w-28 ${className}`}
      >
        <LoadingSpinner className="w-3 h-3" />
      </div>
    );
  }

  if (data === undefined) return null;

  return (
    <div
      className={`flex justify-center items-center bg-button-background px-2.5 py-1 rounded-3xl text-icon text-xs w-28 ${className}`}
    >
      1TON = <span className="pl-0.5">${data}</span>
    </div>
  );
};
