import { Skeleton } from "./Skeleton";

export const Deadline = ({ deadlineTime }: { deadlineTime: string }) => {
  return (
    <div className="flex flex-col w-full justify-center items-center text-white mb-10">
      <p className="text-xs bg-gradient-to-r from-gradient-yellow to-gradient-green text-transparent bg-clip-text">
        Deadline time
      </p>
      {deadlineTime ? (
        <p className="text-xl bg-gradient-to-r from-gradient-yellow to-gradient-green text-transparent bg-clip-text">
          {deadlineTime}
        </p>
      ) : (
        <Skeleton />
      )}
    </div>
  );
};
