import romanApi, { type RomanApiError } from "@/lib/net/romanApi";
import type { RoundEntryPrize } from "@/types/RoundEntryPrize";
import { useEffect } from "react";
import useSWR from "swr";

type PrizePayoutResponse = {
  roundEntryPrize: RoundEntryPrize[];
};

const fetcher = (url: string) => romanApi.get<PrizePayoutResponse>(url);

export const usePrizePayout = (roundId: string, handleError: (err: RomanApiError) => void) => {
  const { data, error, isLoading } = useSWR<PrizePayoutResponse, RomanApiError>(`/prizes/${roundId}`, fetcher);

  useEffect(() => {
    if (error) {
      handleError(error);
    }
  }, [error, handleError]);

  return {
    data,
    isLoading,
  };
};
