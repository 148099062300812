export const InfoIcon = ({
  onClick,
  className,
}: {
  onClick: (arg: any) => void;
  className?: string;
}) => {
  return (
    <img
      onClick={onClick}
      className={`hover:cursor-pointer ${className}`}
      src="/assets/info-icon.svg"
      alt="info icon"
      draggable={false}
    />
  );
};
