import type { EntryDetails } from "@/types/EntryDetails";
import { useState } from "react";

export const useEntryDetails = (userId: string) => {
  if (!userId) throw new Error("userId is required");

  const [entryDetails, setEntryDetails] = useState<EntryDetails>({
    userId,
    roundType: null,
    playerIds: [],
    entries: [],
    detail: {
      type: null,
      captainPlayerId: null,
      viceCaptainPlayerId: null,
    },
  });

  return {
    entryDetails,
    setEntryDetails,
  };
};
