export type Players = {
  name: string;
  score: number;
};
export const PLAYERS = [
  {
    name: "Goals",
    score: 10,
  },
  {
    name: "Goals",
    score: 10,
  },
  {
    name: "Goals",
    score: 10,
  },
  {
    name: "Goals",
    score: 10,
  },
  {
    name: "Goals",
    score: 10,
  },
  {
    name: "Goals",
    score: 10,
  },
  {
    name: "Goals",
    score: 0,
  },
  {
    name: "Goals",
    score: 0,
  },
  {
    name: "Assists",
    score: 0,
  },
  {
    name: "Assists",
    score: 0,
  },
  {
    name: "Assists",
    score: -10,
  },
  {
    name: "Assists",
    score: -10,
  },
  {
    name: "Assists",
    score: -10,
  },
  {
    name: "Shot",
    score: -10,
  },
  {
    name: "Shot",
    score: -10,
  },
  {
    name: "Shot",
    score: -10,
  },
];
