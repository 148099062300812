import { v4 as uuidv4 } from "uuid";
import { PLAYERS, type Players } from "./fixture";

export const FantasyPointChart = () => {
  return (
    <div className="flex flex-col gap-3 px-4">
      <Section title="All" players={PLAYERS} />
      <Section title="Goalkeper" players={PLAYERS} />
    </div>
  );
};

const Section = ({ title, players }: { title: string; players: Players[] }) => {
  return (
    <div className="">
      <p className="text-sm text-icon mb-2">{title}</p>
      <div className="flex flex-col gap-3 px-4">
        {players.map((data) => (
          <ChartRow {...data} key={uuidv4()} />
        ))}
      </div>
    </div>
  );
};

const ChartRow = ({ name, score }: Players) => {
  return (
    <div className="flex justify-between items-center w-full">
      <p className="text-xs text-white">{name}</p>
      <p className="text-xs text-transparent bg-clip-text bg-gradient-to-r from-gradient-yellow to-gradient-green">
        {score > 0 && "+"}
        {score} Pts
      </p>
    </div>
  );
};
