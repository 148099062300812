import romanApi, { type RomanApiError } from "@/lib/net/romanApi";
import type { Key } from "swr";
import useSWRMutation from "swr/mutation";

interface WithdrawalArg {
  destUserAddress: string;
  nanoTon: string;
  userId: string;
  // provided by the telegram sdk to authenticate the user
  initDataRaw: string;
}

interface WithdrawalSuccessResponse extends WithdrawalArg {
  message: string;
}

const postWithdrawalRequest = async (url: string, { arg }: { arg: WithdrawalArg }) => {
  return romanApi.post<WithdrawalSuccessResponse, WithdrawalArg>(url, arg);
};

export const usePostWithdrawalRequest = () => {
  const { data, isMutating, trigger, error } = useSWRMutation<
    WithdrawalSuccessResponse,
    RomanApiError,
    Key,
    WithdrawalArg
  >("/payment/withdrawal", postWithdrawalRequest);

  return {
    data,
    trigger,
    isMutating,
    isError: error !== undefined,
    error,
  };
};
