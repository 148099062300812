import romanApi, { type RomanApiError } from "@/lib/net/romanApi";
import type { RoundEntryPrize } from "@/types/RoundEntryPrize";
import { useEffect } from "react";
import useSWR from "swr";

interface RoundEntryPrizeResponse {
  roundEntryPrize: RoundEntryPrize[];
}

const fetcher = (url: string) => romanApi.get<RoundEntryPrizeResponse>(url);

export const useSoccerEntryPrize = (roundId: string, handleError: (err: RomanApiError) => void) => {
  const { data, error, isLoading } = useSWR<RoundEntryPrizeResponse>(`/prizes/${roundId}`, fetcher);

  useEffect(() => {
    if (error) {
      handleError(error);
    }
  }, [error, handleError]);

  return {
    data: data ?? { roundEntryPrize: [] },
    isLoading,
  };
};
