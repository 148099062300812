import romanApi, { type RomanApiError } from "@/lib/net/romanApi";
import type { UserRoundEntryUnit } from "@/types/UserRoundEntryUnit";
import { useEffect } from "react";
import useSWR from "swr";

interface RoundEntryUnitsResponse {
  userRoundEntryUnits: UserRoundEntryUnit[];
}

const fetcher = (url: string) => romanApi.get<RoundEntryUnitsResponse>(url);

export const useRoundEntryUnits = (userId: string, handleError: (err: RomanApiError) => void) => {
  const { data, error, isLoading } = useSWR<RoundEntryUnitsResponse>(`/soccer/roundEntryUnits/${userId}`, fetcher);

  useEffect(() => {
    if (error) {
      handleError(error);
    }
  }, [error, handleError]);

  return {
    data: data ?? { userRoundEntryUnits: [] },
    isLoading,
  };
};
