import RomanApi, { type RomanApiError } from "@/lib/net/romanApi";
import { useEffect } from "react";
import type { Key } from "swr";
import useSWRMutation from "swr/mutation";

const postPurchaseEntryRequest = async (url: string, { arg }: { arg: string }) => {
  return await RomanApi.post(url, { entryTxId: arg });
};

export const usePurchaseEntry = (handleError: (err: RomanApiError) => void) => {
  const { data, isMutating, trigger, error } = useSWRMutation<any, RomanApiError, Key, string>(
    "/soccer/entry/purchase",
    postPurchaseEntryRequest,
  );

  useEffect(() => {
    if (error) {
      handleError(error);
    }
  }, [error, handleError]);

  return {
    data,
    trigger,
    isMutating,
  };
};
