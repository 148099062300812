import { PrimaryButton } from "@/components/Buttons/PrimaryButton";
import { CustomModal } from "@/components/CustomModal";

interface SuccessModalProps {
  isOpen: boolean;
  onClose: () => void;
  onClick: () => void;
}

export const SuccessModal = ({ isOpen, onClose, onClick }: SuccessModalProps) => {
  return (
    <CustomModal isOpen={isOpen} onClose={onClose}>
      <div className="flex flex-col text-white text-center">
        <div className="flex flex-col text-white text-center items-center gap-3">
          <img className="w-16 h-16" src="/assets/success-icon.svg" alt="success icon" />
          <p className="text-base">purchase successfully!</p>
        </div>
        <PrimaryButton className="w-full mt-6" text="ADD ENTRY" onClick={onClick} />
      </div>
    </CustomModal>
  );
};
