import RomanApi, { type RomanApiError } from "@/lib/net/romanApi";
import type { EntryDetails } from "@/types/EntryDetails";
import { useEffect } from "react";
import type { Key } from "swr";
import useSWRMutation from "swr/mutation";

export interface EntryResponse {
  entryTx: {
    id: string;
  };
}

const postCreateEntryRequest = async (url: string, { arg }: { arg: EntryDetails }) => {
  return await RomanApi.post<EntryResponse, EntryDetails>(url, arg);
};

export const useCreateEntry = (handleError: (err: RomanApiError) => void) => {
  const { data, isMutating, trigger, error } = useSWRMutation<EntryResponse, RomanApiError, Key, EntryDetails>(
    "/soccer/entry",
    postCreateEntryRequest,
  );

  useEffect(() => {
    if (error) {
      handleError(error);
    }
  }, [error, handleError]);

  return {
    data,
    trigger,
    isMutating,
  };
};
